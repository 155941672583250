import { patchEnduserPinCodeUpdateBySelfResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const patchEnduserPinCodeUpdateBySelf = async (
  sessionToken: string,
  newPinCode: string,
) => {
  const res = await axiosClient.patch<patchEnduserPinCodeUpdateBySelfResponse>(
    `${fullUrl('api')}/endusers/update_pin_code_by_self`,
    {
      session_token: sessionToken,
      new_pin_code: newPinCode,
    },
  )
  return res
}
